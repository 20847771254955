import { lazy, Suspense, useEffect, useState } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch, useSelector } from "react-redux";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { Route, Switch } from "react-router-dom";
import Register from "./jsx/pages/Registration";

import Loginn from "./jsx/pages/Login";
import { checkAuth } from "./store/actions/Auth";
import firebase, { fetchToken, onMessageListener } from "./utils/firebase";
import CallModal from "./jsx/components/modal/CallModal";
import { callStatus, rejectCall } from "./store/actions/User";

import Availability from "./jsx/components/Dashboard/Availability";
import makeToast from "./utils/Toaster";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state._auth);
  const { call_status, call_data } = useSelector((state) => state._user);
  const [callData, setcallData] = useState("");

  const [visible, setvisible] = useState(true);

  console.log("Redux Call Status Data", call_status, call_data);

  let timeOut = null;
  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  onMessageListener()
    .then((payload) => {
      setcallData(payload.data);
      console.log("Payload ", payload);
      const callType = payload?.data?.type;
      console.log("Type Call", callType);
      if (callType === "reject") {
        dispatch(callStatus(false, null));
        makeToast("error", "Call has been rejected");
      }
      if (callType === "Incoming") {
        dispatch(callStatus(true, payload.data));
        timeOut = setTimeout(() => {
          dispatch(callStatus(false, null));
          const callData = JSON.parse(payload?.data?.data);
          dispatch(
            rejectCall({
              isUser: false,
              call_id: callData?._id,
            })
          );
        }, 20000);
      }
    })
    .catch((err) => console.log("failed: ", err));

  let routes = (
    <Switch>
      <Route exact path="/">
        <Loginn />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      {/* <Route exact path="/availability">
        <Availability />
      </Route> */}
    </Switch>
  );
  if (isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
        <CallModal
          setvisible={(data) => dispatch(callStatus(data))}
          visible={call_status}
        />
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

export default App;
