import { console_log, isArrayCheck, Log } from "../../../utils/helper";
import request from "../../../utils/request";
import makeToast from "../../../utils/Toaster";
import {
  CALL_DATA,
  CALL_STATUS,
  GET_ALL_USERS,
  GET_MY_APOINTMENTS,
  GET_MY_PROFILE,
} from "../Action.Constant";
import pic1 from "../../../images/profile/small/pic1.jpg";

export const updateUserProfile = (userData) => async (dispatch) => {
  console_log("updateUserProfile", userData);
  try {
    const res = await request.post("/doctors/updateProfile", userData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      // makeToast("success", message);
      console_log("Data", data);

      // navigate("/");
    }
  } catch (e) {
    console_log("updateUserProfile error", e);

    // makeToast("updateUserProfile error", e.message);
  }
};

export const getDoctorDataById = (userData) => async (dispatch) => {
  Log("getDoctorDataById call", userData);

  try {
    const res = await request.post("/doctors/getDoctorbyid", userData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      dispatch({
        type: GET_MY_PROFILE,
        payload: data?.doctor,
      });
    }
  } catch (e) {
    Log("getDoctorDataById error ", e);
  }
};

export const apointmentBooking = (userData) => async (dispatch) => {
  console_log("apointmentBooking", userData);
  try {
    const res = await request.post("/call/appointmentCall", userData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      // makeToast("success", message);
      console_log("Data", data);

      // navigate("/");
    }
  } catch (e) {
    console_log("apointmentBooking error", e);

    // makeToast("apointmentBooking error", e.message);
  }
};

export const acceptCall = (userData) => async (dispatch) => {
  console_log("acceptCall", userData);
  try {
    const res = await request.post("/call/callAccept", userData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      console_log("Data Success acceptCall", data);
    }
  } catch (e) {
    console_log("acceptCall error", e);
  }
};

export const rejectCall = (userData) => async (dispatch) => {
  console_log("rejectCall", userData);
  try {
    const res = await request.post("/call/callReject", userData);
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      console_log("Data Success rejectCall", data);
    }
  } catch (e) {
    console_log("rejectCall error", e);
  }
};

export const getAllUserListing = () => async (dispatch) => {
  console_log("getAllUserListing");
  try {
    const res = await request.get("/users/allUsers");
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      console_log("Data", data?.data);
      dispatch({
        type: GET_ALL_USERS,
        payload: data?.data,
      });
    }
  } catch (e) {
    console_log("getAllUserListing error", e);

    // makeToast("getAllUserListing error", e.message);
  }
};

export const getMyApointments = () => async (dispatch) => {
  console_log("getMyApointments");
  try {
    const res = await request.get("/doctors/getCurrentDoctorAppointment");
    const { message, status, data } = res.data;
    if (!status) throw res.data;
    if (status) {
      console_log("Data", data?.data);
      dispatch({
        type: GET_MY_APOINTMENTS,
        payload: data?.data,
      });
    }
  } catch (e) {
    console_log("getMyApointments error", e);

    // makeToast("getAllUserListing error", e.message);
  }
};

export const makeData = (data) => {
  return isArrayCheck(data) && data.length > 0
    ? data?.map((obj, id) => ({}))
    : [];
};

export const callStatus = (data, callData) => (dispath) => {
  dispath({
    type: CALL_STATUS,
    payload: data,
  });
  dispath(getcallData(callData));
};

export const getcallData = (data) => (dispath) => {
  dispath({
    type: CALL_DATA,
    payload: data,
  });
  // window.location.reload();
};
