import React, { useState } from "react";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import {
  getAvailabilityByDate,
  isArrayCheck,
  isDateEqual,
} from "../../../utils/helper";
import { Day_Availability } from "../../../utils/constants";
import AvailabilitySlots from "../Availability-Components/AvailabilitySlots";
import { useSelector } from "react-redux";

const Availability = () => {
  const [value, onChange] = useState(new Date());
  const { user } = useSelector((state) => state._user);
  console.log("User Data =>", user);
  const handleDate = (e) => {
    onChange(e);
    // let dat = user?.avalibility;
    // dat = getAvailabilityByDate(dat, e);
    // Log('handle Change Date Find', dat);
    // if (dat) {
    //     dispatch(selectedApointments([dat]));
    // } else {
    //     dispatch(refreshApointments(e));
    // }
  };

  const isHighlight = (date) => {
    if (getAvailabilityByDate(user?.avalibility, date)) {
      console.log(
        "is Highlighted Date",
        date,
        getAvailabilityByDate(user?.avalibility, date)
      );
      return true;
    }
  };
  return (
    <div className="availability-slots">
      <div className="heading">
        <h2>Availability</h2>
      </div>
      <div className="row">
        <div className="col-md-6 align-date">
          <Calendar
            onChange={handleDate}
            value={value}
            isHighlight={isHighlight}
          />
        </div>
        <div className="col-md-6">
          {isArrayCheck(user?.avalibility) &&
            user?.avalibility.length > 0 &&
            user?.avalibility.map((data, index) =>
              isDateEqual(data?.dateOfAvailability, value) ? (
                <AvailabilitySlots
                  time={data.dateOfAvailability}
                  slots={data.slots}
                  index={index}
                  date={value}
                />
              ) : null
            )}
        </div>
        <div className="col-md-12">
          <div className="text-center mt-5">
            <div className="btn btn-primary " onClick={() => {}}>
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
