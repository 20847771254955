import React from "react";
import { isArrayCheck } from "../../../utils/helper";
import SlotCheckbox from "./SlotCheckbox";
function AvailabilitySlots({ time, slots, index, date }) {
  return (
    <div className="availability-slots">
      <div className="d-flex align-items-center">
        <div className="slots">
          <div className="row">
            <div className="col-md-12 d-flex flex-row flex-wrap">
              {isArrayCheck(slots) &&
                slots.length > 0 &&
                slots.map((t, id) => (
                  <SlotCheckbox key={id} label={t.startTime} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailabilitySlots;
