import { combineReducers } from "redux";

import Auth from "./Auth/index";
import User from "./User";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  _auth: Auth,
  _user: User,
});

export default reducer;
