import React, { Fragment, useEffect, useRef, useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  callStatus,
  getAllUserListing,
  getMyApointments,
} from "../../../store/actions/User/index.js";
import {
  fetchToken,
  onMessageListener,
} from "../../../utils/firebase/index.js";
import {
  formatedDate,
  formatedTime,
  isArrayCheck,
  NOT_FOUND_IMAGE,
} from "../../../utils/helper.js";
import data from "./tableData.js";
import DataTable from "react-data-table-component";
import {
  APPOINTMENT_HERADER,
  COLUMN_HERADER,
} from "../../../utils/Header/index.js";
import CallModal from "../modal/CallModal.js";
import ModalContent from "./Modal.js";

const Appointment = () => {
  const [ApointmnetDetails, setApointmnetDetails] = useState("");
  const [open, setopen] = useState(false);
  const { appointments_listing } = useSelector((state) => state._user);
  const [row, setrow] = useState([]);
  const [callData, setcallData] = useState("");
  const { call_status } = useSelector((state) => state._user);

  useEffect(() => {
    if (appointments_listing) {
      makeRow();
    } else {
      dispatch(getAllUserListing());
    }
  }, [appointments_listing]);
  //  id: 0, designer: 'Salman', price: 100, buyer: 'Jhon', buyerAddress: 'Lahore', status: 'pending'

  const makeRow = () => {
    var data =
      Array.isArray(appointments_listing) && appointments_listing.length > 0
        ? appointments_listing.map((data, id) => ({
            id: id,
            userName: data?.user?.firstname,
            booking_date: formatedDate(data?.created_at),
            booking_time: formatedTime(data?.created_at),
            status: data?.call_status,
            action: (
              <div className="d-flex align-items-center">
                {/* <button className="btn btn-sm btn-primary rounded-circle detail-btn mx-2">
                  <i className="fa fa-phone"></i>
                </button> */}
                <button
                  onClick={() => {
                    setApointmnetDetails(data);
                    setopen(true);
                  }}
                  className="btn btn-sm btn-primary rounded-circle detail-btn mx-2"
                >
                  <i className="fa fa-info"></i>
                </button>
              </div>
            ),
          }))
        : [];
    // console.log('Row Data', appointments_listing);
    setrow(data);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    fetchToken(dispatch);
    dispatch(getMyApointments());
  }, []);

  return (
    <div>
      <div className="heading">
        <h2>Appointment</h2>
      </div>
      <div className="card">
        <div className="card-body">
          <DataTable
            columns={COLUMN_HERADER.appointment_listing_header}
            data={row}
            defaultSortFieldId={1}
            pagination
            responsive
          />
        </div>
      </div>
      <ModalContent
        active={open}
        onClick={() => setopen(false)}
        data={ApointmnetDetails}
      />
    </div>
  );
};

export default Appointment;
