export const GET_USER = "GET_USER";
export const AUTH_USER = "AUTH_USER";
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_MY_APOINTMENTS = "GET_MY_APOINTMENTS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const USER_LOGOUT = "USER_LOGOUT";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_NOT_APPROVED = "GET_ALL_PRODUCTS_NOT_APPROVED";
export const GET_ALL_PRODUCTS_REVIEWS = "GET_ALL_PRODUCTS_REVIEWS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_VARIANT = "ADD_VARIANT";
export const VARIANT_IMAGE = "VARIANT_IMAGE";
export const ALL_PRODUCT_IMAGE = "ALL_PRODUCT_IMAGE";
export const ALL_PRODUCT_VISUALIZATION_IMAGES =
  "ALL_PRODUCT_VISUALIZATION_IMAGES";
export const CLEAR_VARIANT_IMAGE = "CLEAR_VARIANT_IMAGE";
export const CLEAR_VARIANT = "CLEAR_VARIANT";
export const CLEAR_PRODUCT_IMAGE = "CLEAR_PRODUCT_IMAGE";
export const CLEAR_USER = "CLEAR_USER";
export const CLEAR_PRODUCTS_LISTING = "CLEAR_PRODUCTS_LISTING";

export const GET_ORDER = "GET_ORDER";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";

export const GET_BLOG = "GET_BLOG";
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_BLOG_IMAGE = "GET_BLOG_IMAGE";
export const CLEAR_BLOG_IMAGE = "CLEAR_BLOG_IMAGE";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY_IMAGE = "GET_CATEGORY_IMAGE";
export const CLEAR_CATEGORY_IMAGE = "CLEAR_CATEGORY_IMAGE";

export const GET_SETTING = "GET_SETTING";
export const GET_BANNER_IMAGE = "GET_BANNER_IMAGE";
export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_SLIDER_IMAGE = "GET_SLIDER_IMAGE";
export const CLEAR_SETTING_IMAGES = "CLEAR_SETTING_IMAGES";
export const GET_CONTACT_US = "GET_CONTACT_US";
export const GET_SOCIALS = "GET_SOCIALS";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_GALLERY = "GET_GALLERY";
export const GET_DESIGNERS_PROFIT = "GET_DESIGNERS_PROFIT";

//Web Side
export const GET_HOME_PAGE_DATA = "GET_HOME_PAGE_DATA";
export const GET_HOME_ALL_BLOGS = "GET_HOME_ALL_BLOGS";
export const GET_HOME_ALL_PRODUCTS = "GET_HOME_ALL_PRODUCTS";
export const GET_PRODUCTS_BY_DESIGNER = "GET_PRODUCTS_BY_DESIGNER";
export const GET_PRODUCTS_BY_CATEGORY = "GET_PRODUCTS_BY_CATEGORY";
export const GET_DESIGNER_DETAIL = "GET_DESIGNER_DETAIL";
export const CLEAR_PRODUCTS_WEB = "CLEAR_PRODUCTS_WEB";
export const GET_CART = "GET_CART";
export const GET_WISHLIST = "GET_WISHLIST";
export const GET_CART_PRICE = "GET_CART_PRICE";
export const SELECTED_PRODUCTS = "SELECTED_PRODUCTS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ALL_PUBLIC_CATEGORIES = "GET_ALL_PUBLIC_CATEGORIES";

//Drawer
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

//Report
export const GET_REPORT = "GET_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";
export const GET_REPORT_OF_COM_ORDER = "GET_REPORT_OF_COM_ORDER";
export const GET_REPORT_OF_CAN_ORDER = "GET_REPORT_OF_CAN_ORDER";
export const GET_REPORT_PRODUCT_ID = "GET_REPORT_PRODUCT_ID";
export const GET_REPORT_PRODUCT_ID_STATUS = "GET_REPORT_PRODUCT_ID_STATUS";
export const GET_REPORT_BY_USERID = "GET_REPORT_BY_USERID";
export const CLEAR_USER_REPORT = "CLEAR_USER_REPORT";
export const GET_REPORT_SOLD_PRODUCTS = "GET_REPORT_SOLD_PRODUCTS";

//NOTIFICATIONS
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

// Message

export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_CHAT = "GET_CHAT";

//Reviews
export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";
export const CLEAR_PRODUCT_REVIEWS = "CLEAR_PRODUCT_REVIEWS";

//about_us
export const GET_ABOUT_US = "GET_ABOUT_US";

export const CALL_STATUS = "CALL_STATUS";
export const CALL_DATA = "CALL_DATA";
