import React, { Fragment, useEffect, useRef, useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  callStatus,
  getAllUserListing,
  getMyApointments,
} from "../../../store/actions/User/index.js";
import {
  fetchToken,
  onMessageListener,
} from "../../../utils/firebase/index.js";
import { isArrayCheck, NOT_FOUND_IMAGE } from "../../../utils/helper.js";
import data from "./tableData.js";
import DataTable from "react-data-table-component";
import { COLUMN_HERADER } from "../../../utils/Header/index.js";
import CallModal from "../modal/CallModal.js";

const Home = () => {
  const { users_listing, appointments_listing } = useSelector(
    (state) => state._user
  );
  const [row, setrow] = useState([]);
  const [callData, setcallData] = useState("");
  const { call_status } = useSelector((state) => state._user);

  useEffect(() => {
    if (users_listing) {
      makeRow();
    } else {
      dispatch(getAllUserListing());
    }
  }, [users_listing]);
  //  id: 0, designer: 'Salman', price: 100, buyer: 'Jhon', buyerAddress: 'Lahore', status: 'pending'

  const makeRow = () => {
    var data =
      Array.isArray(users_listing) && users_listing.length > 0
        ? users_listing.map((data, id) => ({
            avatar: (
              <img
                src={NOT_FOUND_IMAGE}
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 100,
                  margin: 5,
                }}
              />
            ),
            _id: data._id,
            id: id + 1,
            name: data?.firstname,
            department: data?.department,
            gender: data?.gender,
            education: "",
            mobile: data?.phone,
            email: data?.email,
            action: (
              <button className="btn btn-sm btn-primary rounded-circle detail-btn mx-2">
                <i className="fa fa-phone"></i>
              </button>
            ),
          }))
        : [];
    // console.log('Row Data', users_listing);
    setrow(data);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    fetchToken(dispatch);
    dispatch(getAllUserListing());
    dispatch(getMyApointments());
  }, []);

  // onMessageListener()
  //   .then((payload) => {
  //     setcallData(payload.data);
  //     dispatch(callStatus(true, payload.data));
  //     console.log("Notifications", payload.data);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  return (
    <div>
      <div className="heading">
        <h2>Dashboard</h2>
      </div>
      <div className="row">
        <div className="col-md-3 text-center">
          <div className="card">
            <div className="card-body p-4">
              <div className="donut-chart-sale">
                <i className="fa fa-calendar"></i>
              </div>
              <h2 className="fs-24 text-black font-w600 mb-0">
                {isArrayCheck(appointments_listing)
                  ? appointments_listing.length
                  : 0}
              </h2>
              <span className="fs-14">Appointments</span>
            </div>
          </div>
        </div>
        {/* <div className="col-md-3 text-center">
          <div className="card">
            <div className="card-body p-4">
              <div className="donut-chart-sale donut-chart-sale-1">
                <i className="fa fa-user"></i>
              </div>
              <h2 className="fs-24 text-black font-w600 mb-0">
                {isArrayCheck(users_listing) ? users_listing.length : 0}
              </h2>
              <span className="fs-14">Users</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center">
          <div className="card">
            <div className="card-body p-4">
              <div className="donut-chart-sale donut-chart-sale-2">
                <i className="fa fa-calendar"></i>
              </div>
              <h2 className="fs-24 text-black font-w600 mb-0">5 Left</h2>
              <span className="fs-14">Dummy Card</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center">
          <div className="card">
            <div className="card-body p-4">
              <div className="donut-chart-sale donut-chart-sale-3">
                <i className="fa fa-calendar"></i>
              </div>
              <h2 className="fs-24 text-black font-w600 mb-0">8 Hours</h2>
              <span className="fs-14">Dummy Card</span>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="card">
        <div className="card-header">
          <h4 className="card-title">Users Listing</h4>
        </div>
        <div className="card-body">
          <DataTable
            columns={COLUMN_HERADER.user_listing_header}
            data={row}
            defaultSortFieldId={1}
            pagination
            responsive
          />
        </div>
      </div> */}
      <CallModal
        setvisible={(data) => dispatch(callStatus(data))}
        visible={call_status}
      />
    </div>
  );
};

export default Home;
