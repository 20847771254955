import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///

import Availability from "../../components/Dashboard/Availability";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
} 

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];


     let firstElem = document.getElementById( 'dashboard' );
    //  let secondElem = document.getElementById( 'availability' );
    //  let thirdElem = document.getElementById( 'appointment' );
    //  firstElem.addEventListener('click', function(){
    //   firstElem.classList.add('mm-active');
    //   // secondElem.classList.remove('mm-active');
    //   // thirdElem.classList.remove('mm-active');
    //  })
    //  secondElem.addEventListener('click', function(){
    //   secondElem.classList.add('mm-active');
    //   firstElem.classList.remove('mm-active');
    //   thirdElem.classList.remove('mm-active');
    //  })
    //  thirdElem.addEventListener('click', function(){
    //   thirdElem.classList.add('mm-active');
    //   firstElem.classList.remove('mm-active');
    //   secondElem.classList.remove('mm-active');
    //  })
    const firstItem = (item) =>{
      if(item === 'dashboard'){
        document.getElementById( 'dashboard' ).classList.add('mm-active');
        document.getElementById( 'availability' ).classList.remove('mm-active');
        document.getElementById( 'appointment' ).classList.remove('mm-active');
      } else if(item === 'availability'){
        document.getElementById( 'availability' ).classList.add('mm-active');
        document.getElementById( 'dashboard' ).classList.remove('mm-active');
        document.getElementById( 'appointment' ).classList.remove('mm-active');
      }
      else{
        document.getElementById( 'appointment' ).classList.add('mm-active');
        document.getElementById( 'dashboard' ).classList.remove('mm-active');
        document.getElementById( 'availability' ).classList.remove('mm-active');
      }
      console.log('First',item)
    }
    



    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li id="dashboard" onClick={() => firstItem('dashboard')} className={`${path === "" ? "mm-active" : ""}`}>
              <Link to="/">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li id="availability" onClick={() => firstItem('availability')} className={`${path === "availability" ? "mm-active" : ""}`}>
              <Link to="/availability">
                <i className="fa fa-calendar"></i>
                <span className="nav-text">Availability</span>
              </Link>
            </li>
            <li id="appointment" onClick={() => firstItem('appointment')} className={`${path === "appointment" ? "mm-active" : ""}`}>
              <Link to="/appointment">
                <i className="fa fa-calendar-check-o"></i>
                <span className="nav-text">Appointment</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
