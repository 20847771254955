import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatedDate, formatedTime } from "../../../utils/helper";

function SlotCheckbox({ label }) {
  const [value, setvalue] = useState(true);
  const [classBtn, setclassBtn] = useState(true);
  const dispatch = useDispatch();

  return (
    <div className={"btn btn-primary m-1"}>
      {moment(label).format("HH:mm a")}
    </div>
  );
}

export default SlotCheckbox;
