import React from "react";
import { Modal, Button } from "react-bootstrap";
import { formatedDate, formatedTime } from "../../../utils/helper";

const ModalContent = ({ onClick, active, data }) => {
  return (
    <Modal className=" fade" id="aAddDietMenus" show={active}>
      <div className="modal-content">
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">Appointment Details</Modal.Title>
          <Button
            variant=""
            className="close"
            data-dismiss="modal"
            onClick={() => onClick()}
          >
            <span>×</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="appointment-details">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="m-0">User Name</h5>
              <p className="m-0">{data?.user?.firstname}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="m-0">Booking Date</h5>
              <p className="m-0">{formatedDate(data?.created_at)}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="m-0">Booking Time</h5>
              <p className="m-0">{formatedTime(data?.created_at)}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="m-0">Status</h5>
              <p className="m-0">{data?.call_status}</p>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ModalContent;
