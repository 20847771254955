export const COLUMN_HERADER = {
  user_listing_header: [
    {
      id: 0,
      name: "Avatar",
      width: "120px",
      selector: (row) => row.avatar,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 1,
      name: "Name",
      width: "150px",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 3,
      name: "Gender",
      width: "150px",
      selector: (row) => row.gender,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 4,
      name: "Education",
      width: "150px",
      selector: (row) => row.education,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 5,
      name: "Mobile",
      width: "150px",
      selector: (row) => row.mobile,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 6,
      name: "Email",
      width: "200px",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 7,
      name: "Action",
      width: "200px",
      selector: (row) => row.action,
      sortable: true,
      reorder: true,
      center: true,
    },
  ],
  appointment_listing_header: [
    {
      id: 0,
      name: "Id",
      width: "100px",
      selector: (row) => row.id,
      center: true,
    },
    {
      id: 0,
      name: "User Name",
      width: "150px",
      selector: (row) => row.userName,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 1,
      name: "Booking Date",
      width: "180px",
      selector: (row) => row.booking_date,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 2,
      name: "Booking Time",
      width: "150px",
      selector: (row) => row.booking_time,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 3,
      name: "Status",
      width: "150px",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
      center: true,
    },
    {
      id: 4,
      name: "Action",
      width: "200px",
      selector: (row) => row.action,
      sortable: true,
      reorder: true,
      center: true,
    },
  ],
};
