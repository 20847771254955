import React, { useEffect, useState } from "react";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import { preloadScript } from "opentok-react";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import makeToast from "../../../utils/Toaster";
// import { dial_tune_off } from "../../../redux/action/chat";

const OT = require("@opentok/client");
const style_ = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  padding: 4,
  height: 100,
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 15,
};

function VideoCall(props) {
  const authData = useSelector((state) => state._auth);
  const history = useHistory();
  const { state } = useLocation();
  const { call } = state;
  console.log("params ==>", call);
  const [audioControl, setaudioControl] = useState(true);
  const [videoControl, setvideoControl] = useState(true);
  const [switchUserFull, setswitchUserFull] = useState(false);
  const [open_, setopen_] = useState(false);
  const [userConneceted, setuserConneceted] = useState(false);
  const [status, setstatus] = useState("");

  const [apiKey, setapiKey] = useState("47501481");
  const [sessionID, setsessionID] = useState(
    "1_MX40NzUwMTQ4MX5-MTY1MjY5OTE0MjY3Nn5FYTJBdzJFOUdUR2kvRW1JTlhtd2h2WUZ-UH4"
  );
  const [token, settoken] = useState(
    "T1==cGFydG5lcl9pZD00NzUwMTQ4MSZzaWc9N2YxMmU4ZmY3ODBjYzQzODFkNTdjMjg5OTEzYTc1NDZiZTZiMzNkYTpzZXNzaW9uX2lkPTFfTVg0ME56VXdNVFE0TVg1LU1UWTFNalk1T1RFME1qWTNObjVGWVRKQmR6SkZPVWRVUjJrdlJXMUpUbGh0ZDJoMldVWi1VSDQmY3JlYXRlX3RpbWU9MTY1MjY5OTE0MyZub25jZT0wLjA3MDk5ODQ5MTQxOTUyMjUyJnJvbGU9bW9kZXJhdG9yJmV4cGlyZV90aW1lPTE2NTMzMDM5NDMmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0R3YWxlZWRhbGkmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD1mb2N1cw=="
  );

  const BtnStyle = {
    height: 50,
    width: 70,
    margin: 5,
    borderColor: "#fff",
  };

  return (
    <div className="">
      <h6>{userConneceted ? "" : "Waiting for the patient to Connect."}</h6>
      <OTSession
        apiKey={call?.callApiKey}
        sessionId={call?.callSecctionId}
        token={call?.callToken}
      >
        <div className="row">
          <div
            className="col-12 d-flex flex-row justify-content-center"
            style={{ position: "relative", height: 430, width: 700 }}
          >
            <OTStreams>
              <OTSubscriber
                properties={{
                  height: 400,
                  width: 700,
                  fitMode: "contain",
                }}
                onSubscribe={() => {
                  console.log("User Connected");
                  setuserConneceted(true);
                  //   dial_tune_off();
                }}
                onError={(err) => {
                  console.log("User Leave...", err);
                }}
                eventHandlers={{
                  destroyed: () => {
                    console.log("Connection Destroyed");
                    history.push("/");
                    makeToast("error", "Call has been endded!");
                  },
                }}
              />
            </OTStreams>
            <div style={{ position: "absolute", top: 30, right: "20%" }}>
              <OTPublisher
                properties={{
                  publishVideo: videoControl,
                  publishAudio: audioControl,
                  height: 125,
                  width: 125,
                  fitMode: "contain",
                }}
              />
            </div>

            {/* <div style={{ position: "absolute", bottom: 10, zIndex: 9999 }}>
              <Button
                variant="outlined"
                onClick={() => setvideoControl(!videoControl)}
                style={BtnStyle}
              >
                {!videoControl ? (
                  <VideocamOff style={{ color: "red" }} fontSize="large" />
                ) : (
                  <Videocam style={{ color: "#fff" }} fontSize="large" />
                )}
              </Button>

              <Button
                variant="outlined"
                onClick={() => setaudioControl(!audioControl)}
                style={BtnStyle}
              >
                {!audioControl ? (
                  <VolumeOff style={{ color: "red" }} fontSize="large" />
                ) : (
                  <VolumeUp style={{ color: "#fff" }} fontSize="large" />
                )}
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  callStatusHandler(2);
                  dial_tune_off();
                }}
                style={BtnStyle}
              >
                <CallEnd style={{ color: "red" }} fontSize="large" />
              </Button>
            </div> */}
          </div>
        </div>
      </OTSession>
      {/* {userConneceted ? ( */}
      <div className="row d-flex justify-content-center mt-5">
        <button
          className="btn btn-sm btn-danger rounded detail-btn mx-2 text-center"
          style={{ width: 100 }}
          onClick={() => {
            history.push("/");
          }}
        >
          End <i className="fa fa-ban"></i>
        </button>
      </div>
      {/* ) : null} */}
      {/* <Modal open={open_}>
        <div style={style_}>
          <div className="text-center">
            <h5>Call has been {status}.</h5>
          </div>
          <div className="d-flex justify-content-around">
            <Button
              variant="contained"
              onClick={() => {
                setopen_(false);
                history.push("/");
              }}
              style={{ backgroundColor: "#ffa500" }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default preloadScript(VideoCall);
